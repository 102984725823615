import React from 'react';

const Header = () => (
  <header>
    <h1 style={{ marginBottom: 5, fontWeight: 'bold' }}>
      Andy Amaya
    </h1>
    <em style={{ color: 'gold', fontSize: 20 }}>
      Front-End Software Engineer
    </em>
  </header>
);

export default Header;
