import React from 'react';
import PropTypes from 'prop-types';

const Project = ({
  title,
  description,
  url,
  repo,
}) => (
  <li className="project-item">
    <h3 style={{ color: '#ff4757', fontWeight: 'bold' }}>{title}</h3>
    <p style={{ height: 100, whiteSpace: 'pre-line' }}>{description}</p>
    <div style={{ width: '100%', height: 50 }}>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: 'white',
          border: '1px solid dodgerblue',
          borderRadius: 3,
          width: '100%',
          display: 'inline-block',
          textAlign: 'center',
          height: 30,
          paddingTop: 10,
          textDecoration: 'none',
        }}
      >
        Visit Site
      </a>
    </div>
    {repo &&
      <div>
        <a
          href={repo}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            marginTop: 10,
            color: '#27ae60',
            border: '1px solid #27ae60',
            borderRadius: 3,
            width: '100%',
            display: 'inline-block',
            textAlign: 'center',
            height: 30,
            paddingTop: 10,
            textDecoration: 'none',
          }}
        >
          See Repo
        </a>
      </div>}
  </li>
);

Project.defaultProps = {
  repo: undefined,
};

Project.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  repo: PropTypes.string,
};

export default Project;
